import './ProfilePage.css';

import { Button, Card, Col, Container, Form, Image, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';

import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const [user, setUser] = useState({});
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [token, setToken] = useState(null);
  const navigate = useNavigate();

  // Fetch token once on component mount
  useEffect(() => {
    const savedToken = localStorage.getItem('token');
    if (savedToken) {
      setToken(savedToken);
    } else {
      alert('You are not authorized. Please login again.');
      navigate('/login'); // Redirect to login if no token found
    }
  }, [navigate]);

  // Fetch user profile information including image
  useEffect(() => {
    if (token) {
      axios
        .get('http://localhost:4000/api/user/get_user', {
          headers: { token }
        })
        .then((response) => {
          setUser(response.data.user);
          setImage(response.data.user.profile_image || 'https://via.placeholder.com/150');
        })
        .catch((error) => console.error('Error fetching user data:', error));
    }
  }, [token]);

  // Handle image change and upload
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  // Handle profile update (both image and other profile data)
  const handleProfileUpdate = async (e) => {
    e.preventDefault(); // Prevent form default submission behavior

    const formData = new FormData();

    // Append profile image if available
    if (imageFile) {
      formData.append('profile_image', imageFile);
    }

    // Append other profile fields (you can add more fields if needed)
    formData.append('customer_name', user.customer_name);
    formData.append('email', user.email);
    formData.append('tel_num', user.tel_num);

    if (!token) {
      alert('You are not authorized. Please login again.');
      return;
    }

    try {
      console.log('Sending profile update with token:', token);  // Log the token for debugging
      const response = await axios.post('http://localhost:4000/api/user/update', formData, {
        headers: {
          token, // Include the token in the headers
          'Content-Type': 'multipart/form-data', // Ensure proper form data content type
        }
      });

      if (response.data.success) {
        alert('Profile updated successfully');
        setIsEditing(false);
        setImage(response.data.profile_image); // Update the profile image in state
      } else {
        alert('Failed to update profile: ' + response.data.message);
      }
    } catch (error) {
      console.error('Error saving changes:', error);  // Log the error for debugging
      alert('Error saving changes');
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="text-center">
            <Card.Header as="h3">My Profile</Card.Header>
            <Card.Body>
              {isEditing ? (
                <>
                  <Form onSubmit={handleProfileUpdate}> {/* Use onSubmit to handle the form */}
                    <Form.Group controlId="formProfileImage">
                      <Form.Label>Profile Image</Form.Label>
                      <Form.Control type="file" accept="image/*" onChange={handleImageChange} />
                    </Form.Group>
                    <Form.Group controlId="formCustomerName">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={user.customer_name}
                        onChange={(e) => setUser({ ...user, customer_name: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        value={user.email}
                        onChange={(e) => setUser({ ...user, email: e.target.value })}
                      />
                    </Form.Group>
                    <Form.Group controlId="formPhoneNumber">
                      <Form.Label>Phone Number</Form.Label>
                      <Form.Control
                        type="text"
                        value={user.tel_num}
                        onChange={(e) => setUser({ ...user, tel_num: e.target.value })}
                      />
                    </Form.Group>
                    <Button variant="primary" className="button-save" type="submit">
                      Save Changes
                    </Button>
                  </Form>
                </>
              ) : (
                <>
                  <Image
                    src={`http://localhost:4000/images/${image}`}
                    className="profile-image"
                    roundedCircle
                    fluid
                    alt="profile-pic"
                  />
                  <Card.Text>
                    <strong>Your Name:</strong> {user.customer_name || 'John Doe'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Your Email:</strong> {user.email || 'example@example.com'}
                  </Card.Text>
                  <Card.Text>
                    <strong>Your Phone Number:</strong> {user.tel_num || 'Not provided'}
                  </Card.Text>

                  <Button variant="primary" className="button-edit" onClick={() => setIsEditing(true)}>
                    Edit Profile
                  </Button>
                </>
              )}
            </Card.Body>
            <Card.Footer className="text-muted">Joined: {user.join_date || 'Date not available'}</Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ProfilePage;
