import H1 from './H1.png'
import H10 from './H10.jpg'
import H11 from './H11.webp'
import H2 from './H2.webp'
import H3 from './H3.jpg'
import H4 from './H4.png'
import H5 from './H5.jpg'
import H6 from './H6.png'
import H7 from './H7.jpg'
import H8 from './H8.jpg'
import H9 from './H9.png'
import MITLogo from './MIT Logo.png'
import admin from './admin.jpg'
import b1 from './b1.jpg'
import b2 from './b2.jpg'
import b3 from './b3.jpg'
import b4 from './b4.jpg'
import email from './email.png'
import expo from './Expo.png'
import facebook_icon from './facebook_icon.png'
import g from './g.webp'
import g1 from './g1.jpg'
import g2 from './g2.webp'
import g3 from './g3.jpg'
import g5 from './g5.webp'
import g6 from './g7.jpg'
import icon from './icon.png'
import linkedin_icon from './linkedin_icon.png'
import phone from './phone.png'
import s1 from './s1.jpg'
import s2 from './s2.webp'
import s3 from './s3.jpg'
import s4 from './s4.jpg'
import s5 from './s5.jpg'
import td from './td.png'
import td2 from './td2.png'
import twitter_icon from './twitter_icon.png'
import up from './up.png'

export const  assest = {
    expo,MITLogo,email,phone,facebook_icon,twitter_icon,linkedin_icon,td,H1,H2,H3,td2,up,admin,H5,H4,H6,H7,H8,H9,H10,H11,g,g1,g2,g3,g5,g6,icon,b1,b2,b3,b4,s1,s2,s3,s4,s5
}

